import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
// import MySwiper from "./swiper/swiper"
import Swiper from "react-id-swiper"
// import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import "swiper/css/swiper.css"
import { Pagination } from "swiper/js/swiper.esm"
import { reviewData } from "../../components/reviewData"
import "./testi-new.scss"
// import { FaUserCircle } from "react-icons/fa"
import { AiFillLinkedin } from "react-icons/ai"
import UserPlaceHolder from "../../images/new-images/testimonial-images/user.png"

const params = {
  modules: [Pagination],
  slidesPerView: 1,
  spaceBetween: 100,
  containerClass: "swiper-container pb-5",
  nested: true,
  autoplay: false,
  pagination: {
    el: ".test-swiper-contact-pagination",
    type: "bullets",
    clickable: true,
  },
}

function TestimonialSwiper() {
  const [expandedIndex, setExpandedIndex] = useState(null)

  const toggleExpansion = index => {
    setExpandedIndex(index === expandedIndex ? null : index)
  }

  return (
    <section className="intro our-approach-testi">
      <div
        className="new-testimonial "
        style={{ paddingBottom: " 0px !important" }}
      >
        <Swiper {...params}>
          {reviewData.map((item, idx) => {
            return (
              <div className="testi-item">
                <div className="testi-item-inner">
                  <div className="testimonial-content newContent">
                    <p className="text-left">
                      {expandedIndex === idx
                        ? item.review
                        : `${item.review.slice(0, 400)}...`}
                    </p>
                    <button
                      className="read-more"
                      onClick={() => toggleExpansion(idx)}
                    >
                      {expandedIndex === idx ? "Read Less" : "Read More"}
                    </button>
                  </div>
                  <div className="testimonial-holder mt-4">
                    <div className="testimonial-meta row">
                      <div className="col-md-2 col-sm-3 col-2 pr-0">
                        {item.headShot ? (
                          <img
                            src={item?.headShot}
                            className="img-fluid rounded-circle "
                            alt={item?.reviewer}
                            style={{ width: "70px" }}
                          />
                        ) : (
                          <img
                            src={UserPlaceHolder}
                            className="img-fluid rounded-circle "
                            alt="placeholder image"
                            style={{ width: "70px" }}
                          />
                        )}
                      </div>
                      <div className="col-md-10 col-sm-9 col-10 text-left">
                        <p className="h5 title my-0">{item?.reviewer}</p>
                        <p
                          className="company mb-0"
                          style={{ color: "#8a49a8", fontSize: "13px" }}
                        >
                          {item?.company}
                        </p>
                        {item?.linkedLink && (
                          <Link
                            to={item?.linkedLink}
                            aria-label={`Follow ${item?.reviewer} on LinkedIn`}
                            target="_blank"
                          >
                            <AiFillLinkedin size={"30px"} />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Swiper>
      </div>
      <div className="text-center">
        <a
          className="text-light btn btn-arrow btn-primary mt-3 text-center mx-auto"
          href="https://www.Quickbase.com/partners/quandary-consulting-group"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="see more quandary reviews"
        >
          See More Reviews
        </a>
      </div>
    </section>
  )
}

export default TestimonialSwiper
